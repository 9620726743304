import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import AuthLayout from "./routes/middleware/AuthLayout";
import NonAuthLayout from "./routes/middleware/NonAuthLayout";
import { authRoutes, nonAuthRoutes, userRoutes } from "./routes/AllRoutes";
import UserLayout from "./routes/middleware/UserLayout";
import ScrollToTop from "./components/common/ScrollToTop";

const App = () => {
  // Renders authenticated routes
  const renderAuthRoutes = (routes) => {
    return routes.map((route, idx) => (
      <Route
        key={idx}
        path={route.path}
        element={<AuthLayout>{route.component}</AuthLayout>}
      />
    ));
  };

  // Renders non-authenticated routes
  const renderNonAuthRoutes = (routes) => {
    return routes.map((route, idx) => (
      <Route
        key={idx}
        path={route.path}
        element={<NonAuthLayout>{route.component}</NonAuthLayout>}
      />
    ));
  };

  const renderUserRoutes = (routes) => {
    return routes.map((route, idx) => (
      <Route
        key={idx}
        path={route.path}
        element={<UserLayout>{route.component}</UserLayout>}
      />
    ));
  };

  return (
    <React.Fragment>
      <AuthProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {renderNonAuthRoutes(nonAuthRoutes)}
            {renderAuthRoutes(authRoutes)}
            {renderUserRoutes(userRoutes)}
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </React.Fragment>
  );
};

export default App;

// import React from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Login from './screens/Auth/Login';
// import Register from './screens/Auth/Register';
// import Home from './screens/Home';
// import Dashboard from './screens/Dashboard';
// import { AuthProvider } from './context/AuthContext';

// const App = () => {
//   return (
//     <React.Fragment>
//       <AuthProvider>
//         <BrowserRouter>
//           <Routes>
//             <Route
//               path='/'
//               element={<Home />} />

//             <Route
//               path='/dashboard'
//               element={<Dashboard />} />

//             <Route
//               path='/login'
//               element={<Login />} />

//             <Route
//               path='/register'
//               element={<Register />} />
//           </Routes>
//         </BrowserRouter>
//       </AuthProvider>
//     </React.Fragment>
//   )
// }

// export default App
