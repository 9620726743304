import React, { useEffect } from 'react';
import headerImg from '../../assets/aboutHead.jpeg';
import grpImg from '../../assets/grpSrg.jpeg';
import mission from '../../assets/mission.png';
import vission from '../../assets/vission.png';
import quality from '../../assets/quality.png';
import innovation from '../../assets/inov.png';
import standImg from '../../assets/menstan.jpeg';
import 'aos/dist/aos.css';
import AOS from 'aos';

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);
  const medicalInstitutions = [
    "S.C.B Medical College & Hospital, Cuttack",
    "Gopabandhu Ayurved Mahavidyalaya, Puri",
    "Govt. Ayurvedic College, Balangir",
    "Avina Chandra Homeopathic College, Bhubaneswar",
    "M.K.C.G Medical College & Hospital, Berhampur",
    "Veer Surendra Sai Institute of Medical Science And Research, Burla",
    "F.M Medical College, Balasore",
    "B.B Medical College, Bolangir",
    "S.L.N Medical College & Hospital, Koraput",
    "P.R.M Medical College & Hospital, Baripada"
  ];


  return (
    <React.Fragment>
      <div className='w-full'>
        <img
          data-aos="fade-up"
          src={headerImg}
          alt="header-img"
          className=" w-full h-auto"
        />
      </div>
      {/* <div className="relative w-full h-auto bg-cover bg-center"
        style={{ backgroundImage: `url(${headerImg})` }}>
        <div className="absolute inset-0 bg-blue-900 bg-opacity-50 flex items-center justify-center">
          <h1 className="text-4xl text-white font-bold">
            About Us
          </h1>
        </div>
      </div> */}

      <div className='px-4 md:px-10'>
        <p className="text-[#74789C] my-6 text-center">
          We pride ourselves of our commitment to quality, integrity and excellence. We are equipped with team of biomedical engineers, team of  doctors and experienced professional dedicated to meeting and exceeding the expectation of our client those who are premier health institutions in eastern India (Odisha, west Bengal, Jharkhand, Assam and Chhattisgarh) , by delivering high end medical devices like Intra operating navigation system, virtual dissection table for integrated anatomy, medical studies and AYUSH studies, high fidelity laparoscopy simulator system to educate a professionals and make them confident to operate high end surgical device.
        </p>

        <div  data-aos="flip-up" className="mb-6">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
              <div className="md:w-1/2 space-y-4">
                <h1 className="text-3xl font-bold text-[#1A202C] tracking-wider">
                  Key Achivments
                </h1>
                <h2 className="text-xl text-[#ACBD1C] tracking-wide">
                  We have Successfully Installed our System in Several Medical Colleges.
                </h2>
                <ul className="list-disc pl-5 space-y-2 text-[#000541] tracking-wider">
                  {medicalInstitutions.map((institution, index) => (
                    <li key={index}>{institution}</li>
                  ))}
                </ul>
              </div>
              <div className="md:w-1/2">
                <img
                  src={grpImg}
                  alt="Surgical Instruments"
                  className="rounded-lg shadow-lg w-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-center">
          <div  data-aos="flip-right" className="bg-white shadow-2xl rounded-lg m-4 p-6 w-full md:w-1/2">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              <div className='flex items-center'>
                <span>
                  <img
                    src={mission}
                    alt="img"
                    className="h-10 w-auto mr-4"
                  />
                </span>
                <span className='border-b-2 border-[#ACBD1C] pb-2'>
                  Our Mission
                </span>
              </div>
            </h2>
            <p className="text-gray-700 text-justify">
              Our mission is to make medical education easy, engaging, and accessible for all aspiring healthcare professions. Join us on a transformative journey, where education transcends conventional limits, unlocking the potential of every learner. Build incredible medical technology products to modernize the healthcare sector.
            </p>
            <p className='text-[#acbd1c] mt-2'>
              * Better Patience Care for Everyone, Everywhere.
            </p>
          </div>

          <div  data-aos="flip-left" className="bg-white shadow-2xl rounded-lg m-4 p-6 w-full md:w-1/2">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              <div className='flex items-center'>
                <span>
                  <img
                    src={vission}
                    alt="img"
                    className="h-10 w-auto mr-4"
                  />
                </span>
                <span className='border-b-2 border-[#ACBD1C] pb-2'>
                  Our Vission
                </span>
              </div>
            </h2>
            <p className="text-gray-700 text-justify">
              Through innovation, ingenuity and dedication to our customer, our vision is to be a diversified healthcare company focused on providing best in class products and services which improve patient care and enhance the quality of people’s lives. Reshape healthcare through innovative medical technology solutions.
            </p>
            <p className='text-[#acbd1c] mt-2'>
              * To Consistently Deliver World Class Medical Devices – SAFE and RELIABLE.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-center ">
          <div data-aos="flip-left" className="w-full md:w-1/2 md:p-4 flex justify-center">
            <img src={standImg}
              alt="Sample"
              className="rounded-lg shadow-2xl" />
          </div>

          <div className="w-full md:w-1/2 flex flex-col">
            <div data-aos="flip-down" className="bg-white shadow-2xl rounded-lg md:m-4 p-6 my-6 md:my-0">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                <div className='flex items-center'>
                  <span>
                    <img
                      src={innovation}
                      alt="innovation"
                      className="h-10 w-auto mr-4"
                    />
                  </span>
                  <span className='border-b-2 border-[#ACBD1C] pb-2'>
                    Our Innovation
                  </span>
                </div>
              </h2>
              <p className="text-gray-700 text-justify">
                Always we are committed to introduce to an exciting rate of innovated product developed by our principal. Most of the products are proprietary and potential to significantly enhance our business operation and drive growth. In today rapidly envolving market staying ahead of the curve is essential. Our team has been dedicated to research and developing cutting-edge solution that meet the demands of modern health care facilities to create a medical business landscape. We believe our new product not only meet but exceed client expectation in terms of performance and end user.
              </p>
            </div>

            <div data-aos="flip-up" className="bg-white shadow-2xl rounded-lg md:m-4 p-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                <div className='flex items-center'>
                  <span>
                    <img
                      src={quality}
                      alt="quality"
                      className="h-10 w-auto mr-4"
                    />
                  </span>
                  <span className='border-b-2 border-[#ACBD1C] pb-2'>
                    Quality & Experience
                  </span>
                </div>
              </h2>
              <p className="text-gray-700 text-justify">
                Our product always speaks about the quality and its affrication. Every product carries of warranty of 3 years and CMC of 5 years.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default About
