import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../assets/logo.png';
import loginBg from '../../assets/lgnbg.webp';

const Login = () => {
  const navigate = useNavigate();
  const [phoneNo, setPhoneNo] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loginMsgs, setLoginMsgs] = useState('');

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!phoneNo) {
      isValid = false;
      errors.phoneNo = 'Phone number is required';
    } else if (!/^\d{10}$/.test(phoneNo)) {
      isValid = false;
      errors.phoneNo = 'Invalid phone number, must be 10 digits';
    }

    if (!password) {
      isValid = false;
      errors.password = 'Password is required';
    }

    setErrors(errors);
    return isValid;
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    // const data = JSON.stringify({ phoneNo, password });
    // console.log("object", data)

    let data = JSON.stringify({
      "phone": phoneNo,
      "password": password
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/narayani/user/login`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    setLoading(true);
    axios.request(config)
      .then((response) => {
        console.log("object", JSON.stringify(response?.data?.token));
        localStorage.setItem('adminInfo', JSON.stringify(response?.data?.user));
        localStorage.setItem('token', JSON.stringify(response.data.token));
        setLoading(false);
        navigate('/dashboard')
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setLoginMsgs(error?.response?.data?.error);
      });
  };

  return (
    <React.Fragment>
      <div className="flex w-full min-h-screen">
        <div className="w-full lg:w-1/2 flex items-center justify-center">
          <div className="px-4 sm:px-0 w-full max-w-sm">
            <div className="flex items-center justify-center gap-1">
              <img src={logo} alt="logo" className='w-48 h-auto' />
            </div>

            <div className='my-6 text-center text-lg text-gray-700 font-semibold tracking-wider'>
              Enter Your Credentials To Admin Login
            </div>

            {loginMsgs && <div className="mb-4 text-center text-red-500 text-sm tracking-wider">
              {loginMsgs}
            </div>}

            <div className="mx-auto">
              <form onSubmit={handleLogin} className="grid space-y-3">
                <input
                  type="text"
                  className="h-10 rounded-md px-3 py-6 border text-lg"
                  placeholder="Phone Number"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
                {errors.phoneNo && <div className="text-red-500 text-sm tracking-wider">
                  {errors.phoneNo}
                </div>}

                <input
                  type="password"
                  className="h-10 rounded-md px-3 py-6 border text-lg"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && <div className="text-red-500 text-sm tracking-wider">
                  {errors.password}
                </div>}

                <div className='flex items-center justify-between'>
                  <div
                    onClick={() => navigate("/")}
                    className='cursor-pointer relative'
                  >
                    Home
                  </div>

                  <div
                    onClick={() => navigate("/forgot-password")}
                    className='cursor-pointer relative'
                  >
                    Forgot Password?
                  </div>
                </div>

                <button
                  type="submit"
                  className="h-11 tracking-wider font-medium bg-[#ACBD1C] hover:bg-[#5f6625] text-gray-100 text-lg rounded-md transition ease-in-out hover:duration-300">
                  {loading ? "Please Wait.." : "Log In"}
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="hidden lg:flex justify-center items-center w-1/2 bg-transparent">
          <img src={loginBg} alt="loginImg" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
