import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/small_logo.png'
import logo2 from '../../assets/logo.png'

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const navigate = useNavigate();

    return (
        <div
            className={`fixed z-40 inset-0 top-16 bg-black bg-opacity-80 transition-opacity md:relative md:z-auto lg:bg-transparent ${isOpen ? 'block' : 'hidden'
                }`}
            onClick={toggleSidebar}
        >
            <div
                className="w-64 h-full bg-white shadow-lg md:p-4 relative"
                onClick={(e) => e.stopPropagation()}
            >
                <span onClick={toggleSidebar}
                    className='text-xl text-black absolute top-0 right-5 block md:hidden'>
                    x
                </span>
                <div className="overflow-y-auto py-4 px-3 h-screen">
                    <ul className="space-y-2">
                        <li>
                            <div className="text-lg font-semibold text-gray-900 flex justify-center shadow-md rounded-lg items-center">
                                <img src={logo2} alt="Logo" className='rounded-lg' />
                            </div>
                        </li>
                        <li>
                            <div
                                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-black hover:dark:text-white cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                                onClick={() => navigate('/dashboard')}
                            >
                                <span className="ml-3">Dashboard</span>
                            </div>
                        </li>
                        <li>
                            <div
                                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-black hover:dark:text-white cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                                onClick={() => navigate('/product')}
                            >
                                <span className="ml-3">Products</span>
                            </div>
                        </li>
                        {/* <li>
                            <div className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <span className="ml-3">Profile</span>
                            </div>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
