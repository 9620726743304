import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = ({ toggleSidebar }) => {
    const { isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    return (
        <React.Fragment>
            <nav className="bg-[#000541] text-white flex justify-between py-2 h-16 w-full fixed z-40">
                <div className="flex justify-between w-full items-center px-10">
                    <div className="px-5 py-2">
                        <button onClick={toggleSidebar}>
                            <GiHamburgerMenu size={24} />
                        </button>
                    </div>
                    <div className="flex items-center">
                        {/* <Link to="/dashboard" className="mr-4 hover:text-gray-300">Dashboard</Link> */}
                        <button onClick={handleLogout} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Logout</button>
                    </div>
                </div>
            </nav>

        </React.Fragment>
    );
};

export default Navbar;