import React, { useState, useEffect } from 'react';
import family from '../../assets/family.png';

const Dashboard = () => {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatDate = (date) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    const formatTime = (date) => {
        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    };

    return (
        <div className='p-6 space-y-6'>
            <div className="flex flex-col md:flex-row items-center justify-between bg-gradient-to-r from-[#acbd1c] to-[#505334] text-white p-6 rounded-lg mx-auto">
                <div className="flex-1 md:pr-16">
                    <div className="mb-4">
                        <div className="text-xl md:text-2xl font-semibold">
                            {formatDate(currentDateTime)}
                        </div>
                        <div className="text-lg md:text-xl">
                            {formatTime(currentDateTime)}
                        </div>
                    </div>
                    <h2 className="text-2xl md:text-4xl font-bold mb-4 tracking-wider">
                        Welcome Back, Admin
                    </h2>
                    <p className="mb-2 text-justify text-lg tracking-wide">
                        Your central hub for managing and optimizing the sale of robotic surgery products. This powerful and intuitive platform is designed to streamline operations, offering you comprehensive control and insights into every facet of your business.
                    </p>
                </div>

                <div className="hidden md:flex justify-center mt-4 md:mt-0">
                    <img
                        src={family}
                        alt="Healthcare"
                        className="h-60 w-auto"
                    />
                </div>
            </div>

            <div className="relative md:z-auto -z-10 w-full rounded-lg" style={{ paddingBottom: "56.25%" }}>
                <iframe
                    className="absolute top-0 left-0 w-full h-full rounded-lg"
                    src="https://www.youtube.com/embed/6mhu4E_cZ5Q?rel=0"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                </iframe>
            </div>
        </div>
    );
};

export default Dashboard;
