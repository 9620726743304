import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import { IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import axios from "axios";

const BottomNavbar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropDown, setDropDown] = useState([]);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  console.log("process.env.REACT_APP_API_URL",process.env.REACT_APP_API_URL)

  const getAllProducts = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/narayani/productContent/getAllContent`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setDropDown(response?.data?.data?.map((ele) => ele?.title));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <section className="py-4 flex px-16 bg-white justify-between relative shadow-lg">
      <div className="md:w-2/5">
        <img src={logo} alt="Logo" className="max-h-14 object-cover" />
      </div>
      <div className="lg:flex md:gap-10 gap-5 md:w-3/5 items-center hidden">
        <span
          onClick={() => navigate("/")}
          className="text-center text-lg text-[#74789C] hover:text-[#00000E] cursor-pointer transition ease-in-out duration-300 "
        >
          Home
        </span>
        <span
          onClick={() => navigate("/about")}
          className="text-center text-lg text-[#74789C] hover:text-[#00000E] cursor-pointer transition ease-in-out duration-300 "
        >
          About Us
        </span>
        <span
          onClick={() => navigate("/contact")}
          className="text-center text-lg text-[#74789C] hover:text-[#00000E] cursor-pointer transition ease-in-out duration-300 "
        >
          Contact Us
        </span>
        <span className="flex gap-2 items-center relative group text-center text-lg text-[#74789C] hover:text-[#00000E] cursor-pointer transition ease-in-out duration-300 ">
          Products
          <span>
            <IoMdArrowDropdown size={20} />
          </span>
          <div className="absolute z-10 top-full left-0 bg-white flex flex-col w-[150px] rounded-b-md opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 transform origin-top scale-y-0 group-hover:scale-y-100">
            {dropDown?.map((ele, ind) => (
              <div
                key={ind}
                onClick={() => navigate(`/products/${ele}`)}
                className="text-themeBlack cursor-pointer tracking-wide py-2 hover:bg-[#acbd1c] text-center duration-300 rounded-b-md"
              >
                {ele}
              </div>
            ))}
          </div>
        </span>
      </div>
      {menuOpen && (
        <div className="lg:hidden flex flex-col items-end px-16 absolute top-full left-0 w-full bg-white z-50">
          <span
            onClick={() => {
              toggleMenu();
              navigate("/");
            }}
            className="text-center text-lg text-[#74789C] hover:text-[#00000E] cursor-pointer transition ease-in-out duration-300 my-2"
          >
            Home
          </span>
          <span
            onClick={() => {
              toggleMenu();
              navigate("/about");
            }}
            className="text-center text-lg text-[#74789C] hover:text-[#00000E] cursor-pointer transition ease-in-out duration-300 my-2"
          >
            About Us
          </span>
          <span
            onClick={() => {
              toggleMenu();
              navigate("/contact");
            }}
            className="text-center text-lg text-[#74789C] hover:text-[#00000E] cursor-pointer transition ease-in-out duration-300 my-2"
          >
            Contact Us
          </span>
          <span className="flex gap-2 items-center group relative text-center text-lg text-[#74789C] hover:text-[#00000E] cursor-pointer transition ease-in-out duration-300 my-2">
            Products
            <span>
              <IoMdArrowDropdown size={20} />
            </span>
            <div className="absolute z-10 top-full left-0 bg-white flex flex-col w-[150px] rounded-b-md opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 transform origin-top scale-y-0 group-hover:scale-y-100">
              {dropDown?.map((ele, ind) => (
                <div
                  key={ind}
                  onClick={() => navigate(`/products/${ele}`)}
                  className="text-themeBlack cursor-pointer tracking-wide py-2 hover:bg-[#acbd1c] text-center duration-300 rounded-b-md"
                >
                  {ele}
                </div>
              ))}
            </div>
          </span>
          <button
            onClick={() => {
              toggleMenu();
              navigate("/login");
            }}
            className="bg-[#ACBD1C] px-4 rounded-lg text-white py-2 font-semibold tracking-wide my-2"
          >
            Log In
          </button>
        </div>
      )}
      <div className="w-1/5 flex justify-end items-center gap-2">
        <button
          onClick={() => navigate("/login")}
          className="bg-[#ACBD1C] px-4 rounded-lg text-white py-2 font-semibold tracking-wide hidden lg:block"
        >
          Log In
        </button>
        <div className="block lg:hidden " onClick={toggleMenu}>
          <GiHamburgerMenu size={24} className="text-black cursor-pointer" />
        </div>
      </div>
    </section>
  );
};

export default BottomNavbar;
