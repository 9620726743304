import React, { useState } from 'react';
import UserNabvar from '../../components/common/UserNabvar';
import Footer from '../../components/common/Footer';
import Inquiry from '../../screens/nonAuth/Inauiry';

const UserLayout = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <div>
      <Inquiry
        onClose={closeModal}
        isOpen={isModalOpen}
      />
      <UserNabvar />
      {props.children}
      <Footer openModal={openModal} />
    </div>
  )
}

export default UserLayout
