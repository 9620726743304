import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const EditProduct = () => {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const initialState = {
        title: '',
        banner_url: '',
        banner_title: '',
        banner_description: '',
        about_section_heading: '',
        about_section_heading2: '',
        about_section_paragraph1: '',
        about_section_paragraph2: '',
        about_section_img: ["", "", ""],
        about_section_video_url: '',
        section2_heading: '',
        section2_img_url: '',
        section2_points: [
            { title: '', keyPoints: [''] },
            { title: '', keyPoints: [''] }
        ],
        section3: [
            { img_url: '', title: '', description: '', keyPoints: [''] },
            { img_url: '', title: '', description: '', keyPoints: [''] }
        ],
        section4_heading: '',
        section4_points: [
            { title: '', keyPoints: [''] },
            { title: '', keyPoints: [''] },
            { title: '', keyPoints: [''] },
            { title: '', keyPoints: [''] }
        ]
    }
    const [formData, setFormData] = useState(initialState);
    const [product, setProduct] = useState({});
    const [error, setError] = useState("");

    const preset_key = "techmentee"
    const cloud_name = "denarffyt"

    useEffect(() => {
        getProductById()
    }, [])

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            title: product?.title,
            banner_url: product?.banner?.banner_url,
            banner_title: product?.banner?.title,
            banner_description: product?.banner?.description,
            about_section_heading: product?.about_section1?.heading,
            about_section_paragraph1: product?.about_section1?.paragraph1,
            about_section_heading2: product?.about_section1?.heading2,
            about_section_paragraph2: product?.about_section1?.paragraph2,
            about_section_img: product?.about_section1?.imageArr,
            about_section_video_url: product?.about_section1?.video_url,
            section2_heading: product?.section2?.heading,
            section2_img_url: product?.section2?.img_url,
            section2_points: product?.section2?.points,
            section3: product?.section3,
            section4_heading: product?.section4?.heading,
            section4_points: product?.section4?.points
        }))
    }, [product])

    const getProductById = () => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/narayani/productContent/getContentById/${id}`,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                setProduct(response?.data?.data);
                // console.log("response?.data?.data", response?.data?.data)
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleArrayChange = (e, section, index, subIndex = null) => {
        const { name, value } = e.target;

        if (section === 'about_section_img') {
            const updatedImages = [...formData.about_section_img];
            updatedImages[index] = value;
            setFormData({ ...formData, about_section_img: updatedImages });
        } else {
            const updatedArray = [...formData[section]];

            if (subIndex !== null) {
                const updatedSubArray = value?.split(',').map(point => point.trim());
                updatedArray[index][name] = updatedSubArray;
            } else {
                updatedArray[index][name] = value;
            }

            setFormData({ ...formData, [section]: updatedArray });
        }
    };

    const handleAddPoint = (section) => {
        setFormData({
            ...formData,
            [section]: [...formData[section], { title: '', keyPoints: [''] }]
        });
    };

    const handleRemovePoint = (section, index) => {
        if (formData[section]?.length === 1) {
            return;
        }
        const updatedArray = formData[section]?.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            [section]: updatedArray
        });
    };

    const handleAddSection3 = () => {
        setFormData({
            ...formData,
            section3: [
                ...formData?.section3,
                { img_url: '', title: '', description: '', keyPoints: [''] }
            ]
        });
    };

    const handleRemoveSection3 = (index) => {
        if (formData?.section3?.length === 1) {
            return;
        }
        const updatedSection3 = formData?.section3?.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            section3: updatedSection3
        });
    }

    const handleImageUpload = async (event, name, index) => {
        const file = event.target.files[0];
        if (!file) {
            alert("Please Select An Image")
        }
        const formData2 = new FormData();
        formData2.append("file", file);
        formData2.append("upload_preset", preset_key);
        try {
            const response = await axios.post(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, formData2);
            console.log("response.data.secure_url", response?.data?.secure_url);
            if (name === "about_section_img") {
                const updatedImages = [...formData.about_section_img];
                updatedImages[index] = response?.data?.secure_url;
                setFormData({ ...formData, about_section_img: updatedImages });
            } else if (name === "section3") {
                setFormData((prev) => {
                    const updatedSection = [...prev.section3];
                    console.log("updated", updatedSection)
                    updatedSection[index] = { ...updatedSection[index], img_url: response?.data?.secure_url };
                    return { ...prev, section3: updatedSection };
                });
            } else {
                setFormData((prev) => ({
                    ...prev,
                    [name]: response?.data?.secure_url,
                }));
            }
        } catch (error) {
            console.error('Error uploading image: ', error);
        }
    };

    // console.log("formData2", formData)
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log({ formData });

        let data = JSON.stringify({
            "title": formData?.title,
            "banner": {
                "banner_url": formData?.banner_url,
                "title": formData?.banner_title,
                "description": formData?.banner_description
            },
            "about_section1": {
                "heading": formData?.about_section_heading,
                "paragraph1": formData?.about_section_paragraph1,
                "heading2": formData?.about_section_heading2,
                "paragraph2": formData?.about_section_paragraph2,
                "imageArr": formData?.about_section_img,
                "video_url": formData?.about_section_video_url
            },
            "section2": {
                "img_url": formData?.section2_img_url,
                "heading": formData?.section2_heading,
                "points": formData?.section2_points
            },
            "section3": formData?.section3,
            "section4": {
                "heading": formData?.section4_heading,
                "points": formData?.section4_points,
            }
        });

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/narayani/productContent/update/${id}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(response?.data);
                alert("Product Updated Successfully");
                setFormData(initialState)
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setError(error?.response?.data?.message)
            });
    };

    return (
        <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl w-full space-y-8 bg-white p-10 rounded-lg shadow-md">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-semibold text-gray-900">
                        Edit Product
                    </h2>
                </div>
                <form className="grid space-y-6" onSubmit={handleSubmit}>
                    {/* Banner Section */}
                    <div>
                        <label htmlFor="Title" className="block text-sm font-medium text-gray-700">
                            Title
                        </label>
                        <input
                            id="title"
                            name="title"
                            type="text"
                            required
                            className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                            placeholder="Title"
                            value={formData?.title}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="banner_url" className="block text-sm font-medium text-gray-700">
                            Banner URL
                        </label>
                        <input
                            id="banner_url"
                            name="banner_url"
                            type="file"
                            className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                            placeholder="Banner URL"
                            required={!formData.banner_url}
                            onChange={(event) => handleImageUpload(event, "banner_url", 0)}
                        />
                        {formData.banner_url && (
                            <div className="mt-1">
                                <img src={formData?.banner_url} alt="Banner" className="w-32 h-20 rounded-md" />
                            </div>
                        )}
                    </div>
                    <div>
                        <label htmlFor="banner_title" className="block text-sm font-medium text-gray-700">
                            Banner Title
                        </label>
                        <input
                            id="banner_title"
                            name="banner_title"
                            type="text"
                            required
                            className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                            placeholder="Banner Title"
                            value={formData?.banner_title}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="banner_description" className="block text-sm font-medium text-gray-700">
                            Banner Description
                        </label>
                        <textarea
                            id="banner_description"
                            name="banner_description"
                            required
                            className="mt-1 block w-full h-20 rounded-md px-3 py-2 border text-lg"
                            placeholder="Banner Description"
                            value={formData?.banner_description}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* About Section */}
                    <div>
                        <label htmlFor="about_section_heading" className="block text-sm font-medium text-gray-700">
                            About Section Heading
                        </label>
                        <input
                            id="about_section_heading"
                            name="about_section_heading"
                            type="text"
                            required
                            className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                            placeholder="About Section Heading"
                            value={formData?.about_section_heading}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="about_section_paragraph1" className="block text-sm font-medium text-gray-700">
                            About Section Paragraph 1
                        </label>
                        <textarea
                            id="about_section_paragraph1"
                            name="about_section_paragraph1"
                            className="mt-1 block w-full h-20 rounded-md px-3 py-2 border text-lg"
                            placeholder="About Section Paragraph 1"
                            value={formData?.about_section_paragraph1}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="about_section_paragraph2" className="block text-sm font-medium text-gray-700">
                            About Section Paragraph 2
                        </label>
                        <textarea
                            id="about_section_paragraph2"
                            name="about_section_paragraph2"
                            className="mt-1 block w-full h-20 rounded-md px-3 py-2 border text-lg"
                            placeholder="About Section Paragraph 2"
                            value={formData?.about_section_paragraph2}
                            required
                            onChange={handleInputChange}
                        />
                    </div>
                    {formData?.about_section_img?.map((img, index) => (
                        <div key={index}>
                            <label htmlFor={`about_section_img_${index}`} className="block text-sm font-medium text-gray-700">
                                About Section Image {index + 1}
                            </label>
                            <input
                                id={`about_section_img_${index}`}
                                name={`about_section_img_${index}`}
                                type="file"
                                className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                                placeholder={`Image URL ${index + 1}`}
                                required={!formData?.about_section_img?.[index]}
                                // value={img}
                                // onChange={(e) => handleArrayChange(e, 'about_section_img', index)}
                                onChange={(event) => handleImageUpload(event, "about_section_img", index)}
                            />
                            {formData?.about_section_img && (
                                <div className="mt-1">
                                    <img src={formData?.about_section_img?.[index]} alt="Banner" className="w-32 h-20 rounded-md" />
                                </div>
                            )}
                        </div>
                    ))}
                    <div>
                        <label htmlFor="about_section_video_url" className="block text-sm font-medium text-gray-700">
                            About Section Video URL
                        </label>
                        <input
                            id="about_section_video_url"
                            name="about_section_video_url"
                            type="text"
                            required
                            className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                            placeholder="Video URL"
                            value={formData?.about_section_video_url}
                            onChange={handleInputChange}
                        />
                    </div>

                    {/* Section 2 */}
                    <div>
                        <label htmlFor="section2_heading" className="block text-sm font-medium text-gray-700">
                            Section 2 Heading
                        </label>
                        <input
                            id="section2_heading"
                            name="section2_heading"
                            type="text"
                            className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                            placeholder="Section 2 Heading"
                            value={formData?.section2_heading}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="section2_img_url" className="block text-sm font-medium text-gray-700">
                            Section 2 Image URL
                        </label>
                        <input
                            id="section2_img_url"
                            name="section2_img_url"
                            type="file"
                            // required={!formData?.section2_img_url}
                            className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                            placeholder="Section 2 Image URL"
                            onChange={(event) => handleImageUpload(event, "section2_img_url", 0)}
                        />
                        {formData?.section2_img_url && (
                            <div className="mt-1">
                                <img src={formData?.section2_img_url} alt="section2_img_url" className="w-32 h-20 rounded-md" />
                            </div>
                        )}
                    </div>
                    {formData?.section2_points?.map((point, index) => (
                        <div key={index} className="space-y-6">
                            <div>
                                <label htmlFor={`section2_points_title_${index}`} className="block text-sm font-medium text-gray-700">
                                    Point Title
                                </label>
                                <input
                                    id={`section2_points_title_${index}`}
                                    name="title"
                                    type="text"
                                    className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                                    placeholder="Point Title"
                                    value={point?.title}
                                    onChange={(e) => handleArrayChange(e, 'section2_points', index)}
                                />
                            </div>
                            <div>
                                <label htmlFor={`section2_points_keyPoints_${index}`} className="block text-sm font-medium text-gray-700">
                                    Key Points (comma-separated)
                                </label>
                                <textarea
                                    id={`section2_points_keyPoints_${index}`}
                                    name="keyPoints"
                                    required
                                    className="mt-1 block w-full h-20 rounded-md px-3 py-2 border text-lg"
                                    placeholder="Key Points"
                                    value={point?.keyPoints?.join(', ')}
                                    onChange={(e) => handleArrayChange(e, 'section2_points', index, 0)}
                                />
                            </div>
                        </div>
                    ))}

                    {/* Section 3 */}
                    {formData?.section3?.map((sec, index) => (
                        <div key={index} className="space-y-6">
                            <div>
                                <label htmlFor={`section3_img_url_${index}`} className="block text-sm font-medium text-gray-700">
                                    Image URL
                                </label>
                                <input
                                    id={`section3_img_url_${index}`}
                                    name="img_url"
                                    type="file"
                                    required={!formData?.section3?.[index]?.img_url}
                                    className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                                    placeholder="Image URL"
                                    onChange={(e) => handleImageUpload(e, 'section3', index)}
                                />
                                {formData?.section3?.[index]?.img_url && (
                                    <div className="mt-1">
                                        <img src={formData?.section3?.[index]?.img_url} alt="Image" className="w-32 h-20 rounded-md" />
                                    </div>
                                )}
                            </div>
                            <div>
                                <label htmlFor={`section3_title_${index}`} className="block text-sm font-medium text-gray-700">
                                    Title
                                </label>
                                <input
                                    id={`section3_title_${index}`}
                                    name="title"
                                    type="text"
                                    required
                                    className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                                    placeholder="Title"
                                    value={sec?.title}
                                    onChange={(e) => handleArrayChange(e, 'section3', index)}
                                />
                            </div>
                            <div>
                                <label htmlFor={`section3_description_${index}`} className="block text-sm font-medium text-gray-700">
                                    Description
                                </label>
                                <textarea
                                    id={`section3_description_${index}`}
                                    name="description"
                                    className="mt-1 block w-full h-20 rounded-md px-3 py-2 border text-lg"
                                    placeholder="Description"
                                    required
                                    value={sec?.description}
                                    onChange={(e) => handleArrayChange(e, 'section3', index)}
                                />
                            </div>
                            <button
                                type="button"
                                className="mt-2 bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-md"
                                onClick={() => handleRemoveSection3(index)}
                            >
                                Remove Section
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        className="mt-4 bg-[#ACBD1C] hover:bg-[#5f6625] text-white font-medium py-2 px-4 rounded-md"
                        onClick={handleAddSection3}
                    >
                        Add Section
                    </button>

                    {/* Section 4 */}
                    <div>
                        <label htmlFor="section4_heading" className="block text-sm font-medium text-gray-700">
                            Section 4 Heading
                        </label>
                        <input
                            id="section4_heading"
                            name="section4_heading"
                            type="text"
                            required
                            className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                            placeholder="Section 4 Heading"
                            value={formData?.section4_heading}
                            onChange={handleInputChange}
                        />
                    </div>
                    {formData?.section4_points?.map((point, index) => (
                        <div key={index} className="space-y-6">
                            <div>
                                <label htmlFor={`section4_points_title_${index}`} className="block text-sm font-medium text-gray-700">
                                    Point Title
                                </label>
                                <input
                                    id={`section4_points_title_${index}`}
                                    name="title"
                                    type="text"
                                    required
                                    className="mt-1 block w-full h-10 rounded-md px-3 py-2 border text-lg"
                                    placeholder="Point Title"
                                    value={point.title}
                                    onChange={(e) => handleArrayChange(e, 'section4_points', index)}
                                />
                            </div>
                            <div>
                                <label htmlFor={`section4_points_keyPoints_${index}`} className="block text-sm font-medium text-gray-700">
                                    Key Points (comma-separated)
                                </label>
                                <textarea
                                    id={`section4_points_keyPoints_${index}`}
                                    name="keyPoints"
                                    className="mt-1 block w-full h-20 rounded-md px-3 py-2 border text-lg"
                                    placeholder="Key Points"
                                    value={point?.keyPoints?.join(', ')}
                                    onChange={(e) => handleArrayChange(e, 'section4_points', index, 0)}
                                />
                            </div>
                            <div className="flex justify-end space-x-2">
                                <button
                                    type="button"
                                    onClick={() => handleRemovePoint('section4_points', index)}
                                    className="px-4 py-2 bg-red-500 text-white rounded-md"
                                >
                                    Remove
                                </button>
                                {index === formData?.section4_points?.length - 1 && (
                                    <button
                                        type="button"
                                        onClick={() => handleAddPoint('section4_points')}
                                        className="px-4 py-2 bg-[#ACBD1C] hover:bg-[#5f6625] text-white rounded-md"
                                    >
                                        Add Point
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                    {error &&
                        <span className='text-red-400 tracking-wide text-sm '>
                            {error}
                        </span>}

                    <button
                        type="submit"
                        className="h-11 tracking-wider font-medium bg-[#ACBD1C] hover:bg-[#5f6625] text-gray-100 text-lg rounded-md transition ease-in-out hover:duration-300"
                    >
                        {loading ? "Please Wait.." : "Edit Product"}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default EditProduct