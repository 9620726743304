import React from 'react'
import { MdOutlineLocalPhone } from "react-icons/md";
import { IoMailOutline } from "react-icons/io5";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

const TopNavbar = () => {
    return (
        <div className='py-3 px-16 lg:flex justify-between w-full hidden bg-[#000541]'>
            <div className='flex gap-2'>
                <div className='flex gap-2 items-center'>
                    <MdOutlineLocalPhone className='text-white'/>
                    <span className='text-white tracking-wide text-md'>
                        +91 9438022552 |
                    </span>
                </div>
                <div className='flex gap-2 items-center'>
                    <IoMailOutline className='text-white' />
                    <span className='text-white tracking-wider text-md -mt-1'>
                        narayaniallianceqbc@gmail.com
                    </span>
                </div>
            </div>

            <div className='flex gap-2'>
                <div className='flex gap-2 items-center'>
                    <span className='text-white tracking-wide text-md'>
                        Narayani Alliance |
                    </span>
                </div>
                <div className='flex gap-4 items-center'>
                    <span>
                        <FaFacebookF className='text-white' />
                    </span>
                    <span>
                        <FaTwitter className='text-white' />
                    </span>
                    <span>
                        <FaYoutube className='text-white' />
                    </span>
                    <span>
                        <FaLinkedinIn className='text-white' />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default TopNavbar