import React, { useEffect, useState } from 'react'
import Banner from '../../components/productComponent/Banner'
import ProductContent from '../../components/productComponent/ProductContent'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const Products = () => {
    const { productName } = useParams();
    const [allProducts, setAllProducts] = useState([]);
    const [product, setProduct] = useState({});

    useEffect(() => {
        getAllProducts();
    }, [])

    useEffect(() => {
        setProduct(allProducts?.find(ele => ele?.title === productName))
    }, [productName, allProducts])

    const getAllProducts = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/narayani/productContent/getAllContent`,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                setAllProducts(response?.data?.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <React.Fragment>
            <Banner product={product} />
            <ProductContent product={product} />
        </React.Fragment>
    )
}

export default Products