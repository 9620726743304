import React, { useState } from 'react';
import Navbar from '../components/common/Navbar';
import Sidebar from '../components/common/Sidebar';

const DashboardLayout = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <React.Fragment>
            <div className="h-screen flex flex-col">
                <Navbar toggleSidebar={toggleSidebar} />
                <div className="flex flex-1 overflow-hidden">
                    <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    <main className="flex-grow overflow-y-auto px-4 mt-20">
                        {children}
                    </main>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DashboardLayout;
