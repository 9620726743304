import React, { useEffect } from 'react';
import { GoDotFill } from "react-icons/go";
import dot1 from "../../assets/dot1.png";
import dot2 from "../../assets/dot2.png";
import dot3 from "../../assets/dot3.png";
import dot4 from "../../assets/dot4.png";
import service1 from "../../assets/service1.png";
import service2 from "../../assets/service2.png";
import service3 from "../../assets/service3.png";
import Container from "../../assets/Container.png";
import Container1 from "../../assets/Container2.png";
import Container2 from "../../assets/Container3.png";
import { useNavigate } from 'react-router-dom';
import { CiCalendar } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import 'aos/dist/aos.css';
import AOS from 'aos';

const OurServiceSection = () => {
    const navigate = useNavigate();
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
        });
    }, []);
    const services = [
        {
            title: "Anatomage",
            description: "The Anatomage Table is the most technologically advanced 3D anatomy visualization and virtual dissection tool for anatomy and physiology education and is being adopted by many of the world's leading medical schools and institutions. It has been featured in the TEDTalks Conference, PBS, Fuji TV, and numerous other journals for its innovative approach to digital anatomy presentation. The operating table form factor combined with Anatomage's renowned radiology software and clinical content separates the Anatomage Table from any other imaging system on the market.",
            features: ["Unique", "Accurate", "Functional"],
            image: service1,
            dotImage: dot1,
            dotPosition: "-top-8 -left-2 xl:-left-3"
        },
        {
            title: "LapVision",
            description: "The LapVision simulator has been designed for surgeons and a wide range of medical specialists to safely learn, refine and retain laparoscopic skills. From basic to advanced levels of operation, LapVision provides a comprehensive educational platform that tests technical skills in a variety of surgical scenarios. LapVision can be easily integrated into any surgical curriculum or training program, complete with a library of educational modules of common laparoscopic procedures.",
            features: ["Lapvision Standard", "Lapvision Smart", "Lapvision Hybrid"],
            image: service2,
            dotImage: dot2,
            dotPosition: "-top-8 left-24"
        },
        {
            title: "Robotics System",
            description: "Narayani Allience is a next-generation surgical robotic system used to perform minimal access surgery. The system was designed by CMR Surgical. Versius comprises of a surgeon console; a visualisation bedside unit (a modular robotic arm with and endoscopic camera attachment); and up to three instrument bedside units which can be connected to a range of wristed surgical instruments. Comprehensive and ongoing training lies at the very heart of the CMR Surgical ethos. Working in close collaboration with the surgical community and professional education bodies.",
            features: ["Unique", "Accurate", "Functional"],
            image: service3,
            dotImage: dot3,
            dotPosition: "-top-8 -left-2 xl:-left-4"
        }
    ];

    const blogs = [{
        img: Container,
        description: "Just in time for back to school! Upgrade your auscultation training",
        date: "April 6, 2021",
        name: "Akash Mishra",
    },
    {
        img: Container1,
        description: "How Robotic Surgery is Revolutionising the Operating Room",
        date: "April 6, 2021",
        name: "Akash Mishra",
    }
        ,
    {
        img: Container2,
        description: "Just in time for back to school! Upgrade your auscultation training",
        date: "April 6, 2021",
        name: "Akash Mishra",
    }
    ]
    return (
        <div className='px-4 md:px-10'>
            <div className='flex flex-col justify-center gap-6 overflow-hidden'>
                <h1 className='text-3xl tracking-wider font-bold text-center py-4'>
                    <span className='border-b-2 border-b-[#ACBD1C] py-2'>
                        Our Services
                    </span>
                </h1>

                {services?.map((service, index) => (
                    <div
                        key={index}
                        className={`flex gap-4 md:h-[450px] my-5 flex-col md:flex-row ${index % 2 === 1 ? 'md:flex-row-reverse' : ''}`}
                        data-aos="fade-up"
                    >
                        <div className='md:w-1/2 w-full flex flex-col gap-4 xl:p-4 p-2 justify-center relative'>
                            <h1 className='text-[#1A202C] tracking-wider text-2xl font-semibold relative'>
                                {service?.title}
                                <span className={`absolute ${service?.dotPosition} w-full h-full z-10`}>
                                    <img src={service?.dotImage} alt="Service" className='object-cover opacity-50' />
                                </span>
                            </h1>
                            <p className='text-justify tracking-wide text-[#74789C] xl:leading-8 leading-6'>
                                {service?.description}
                            </p>
                            <ul className='flex flex-wrap justify-between'>
                                {service?.features?.map((feature, index) => (
                                    <li key={index} className='flex gap-2 text-xl items-center'>
                                        <GoDotFill /> {feature}
                                    </li>
                                ))}
                            </ul>
                            <div className='flex justify-start'>
                                <button
                                    onClick={() => navigate(`/products/${service?.title}`)}
                                    className='bg-[#ACBD1C] px-4 py-2 rounded-lg text-white font-semibold tracking-wide'>
                                    Know More
                                </button>
                            </div>
                        </div>
                        <div className='w-full md:w-1/2'>
                            <img src={service?.image} alt="Service" className='h-full w-full object-cover rounded-lg' />
                        </div>
                    </div>
                ))}
            </div>
            <div className='flex flex-col justify-center gap-6'>
                <h1 className='text-3xl tracking-wider font-bold  text-center py-4'>
                    <span className='border-b-2 border-b-[#ACBD1C] py-2'>
                        News & Blogs
                    </span>
                </h1>

                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                    {blogs?.map((v, i) => (
                        <div key={i} className='flex flex-col gap-4 shadow rounded-md p-2'>
                            <img data-aos={i % 2 === 0 ? "flip-right" : "flip-left"}
                                src={v?.img} alt="Blog-img" className='rounded-md' />

                            <h1 className='text-[#182937] font-semibold tracking-wide text-xl text-justify'>
                                {v?.description}
                            </h1>

                            <div className='flex justify-between items-center py-2 w-full'>
                                <span className='flex gap-2 items-center'>
                                    <span>
                                        <CiCalendar />
                                    </span>
                                    <span className='text-[#4B4B6D]'>
                                        {v?.date}
                                    </span>
                                </span>

                                <span className='flex gap-2 items-center'>
                                    <span>
                                        <CiUser />
                                    </span>
                                    <span className='text-[#4B4B6D]'>
                                        {v?.name}
                                    </span>
                                </span>
                            </div>

                            {/* <h3 className='text-[#ACBD1C] tracking-wider text-md font-semibold items-center cursor-pointer flex gap-2'>
                                READ MORE
                                <span>
                                    <IoIosArrowForward />
                                </span>
                            </h3> */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OurServiceSection;
