import React from 'react';
import AboutUsSection from '../../components/homeComponent/AboutUsSection';
import OurServiceSection from '../../components/homeComponent/OurServiceSection';
import Testimonial from '../../components/homeComponent/Testimonial';

const Home = () => {
    return (
        <React.Fragment>
            <AboutUsSection />
            <OurServiceSection />
            {/* <Testimonial /> */}
        </React.Fragment>
    );
}

export default Home;