import React from 'react';
import TopNavbar from './TopNavbar';
import BottomNavbar from './BottomNavbar';

const UserNabvar = () => {
  return (
    <React.Fragment>
      <TopNavbar />
      <BottomNavbar />
    </React.Fragment>
  )
}

export default UserNabvar
