import React, { useEffect, useRef } from 'react';
import img1 from '../../assets/contacts-img-1.webp';
import img2 from '../../assets/contacts-img-2.webp'
import headerImg from '../../assets/contactsHead.jpeg';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.message) {
      errors.message = "Message is required";
    }
    return errors;
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      emailjs.sendForm("service_5gtbihv", "template_b3a8v3r", formRef.current, "HnyojR6K_d1Enn4qw")
        .then(
          (result) => {
            console.log(result.text);
            setLoading(false);
            setFormData({ name: "", email: "", message: "" });
            setErrors({});
            alert("Message Sent Successfully");
          },
          (error) => {
            console.log(error.text);
            setLoading(false);
          }
        );
    }
  };
  return (
    <div className="min-h-screen flex flex-col">
      <div data-aos="fade-up" className='w-full'>
        <img
          src={headerImg}
          alt="header-img"
          className=" w-full h-auto"
        />
      </div>
      <div data-aos="fade-up" className="container mx-auto px-4 py-12 space-y-12">
        <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
          <div data-aos="flip-right" className="md:w-1/2">
            <img
              src={img1}
              alt="Healthcare"
              className="rounded-lg shadow-lg w-full object-cover"
            />
          </div>
          <div data-aos="flip-left" className="md:w-1/2 space-y-4">
            <h2 className="text-2xl font-bold text-gray-800 mb-4 tracking-wider">
              Contact Details
            </h2>
            <p className="text-[#74789C] mb-6">
              We'd love to hear from you. Whether you have a question about our products, services, pricing, or anything else, our team is ready to answer all your questions.
            </p>
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-semibold text-gray-800">Phone</h3>
                <p className="text-[#74789C]">+91 9438022552</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-800">Email</h3>
                <p className="text-[#74789C]">narayaniallianceqbc@gmail.com</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-800">Address</h3>
                <p className="text-[#74789C]"> plot no 529/3, sampur, near sum hospital, Bhubaneswar, odisha -751003</p>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" className="bg-white p-8 rounded-lg shadow-lg flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
          <div data-aos="flip-left" className="md:w-1/2 space-y-6">
            <form onSubmit={handleSubmit} ref={formRef} className="space-y-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-4 tracking-wider">
                Get In Touch
              </h2>

              <div>
                <label className="block text-gray-700 font-semibold mb-2">Name</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Your Name"
                  name='name'
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">Email</label>
                <input
                  type="email"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Your Email"
                  name='email'
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">Message</label>
                <textarea
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Your Message"
                  rows="6"
                  name='message'
                  value={formData.message}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full bg-[#ACBD1C] text-white px-4 py-2 rounded-lg hover:bg-[#768213] focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                  {loading ? "...Loading" : "Send Message"}
                </button>
              </div>
            </form>
          </div>
          <div data-aos="flip-right" className="md:w-1/2">
            <img
              src={img2}
              alt="img"
              className="rounded-lg shadow-lg w-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;