import axios from 'axios';
import React, { useEffect, useState } from 'react'
import product from '../../assets/package.png'
import { useNavigate } from 'react-router-dom';
import edit from '../../assets/edit.png';
import deleteimg from '../../assets/delete.png';

const Product = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getAllProducts();
  }, [])

  const getAllProducts = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/narayani/productContent/getAllContent`,
      headers: {}
    };

    setIsLoading(true);
    axios.request(config)
      .then((response) => {
        setAllProducts(response?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAllProducts([])
        setIsLoading(false);
      });
  }

  const deleteProduct = (id) => {
    // eslint-disable-next-line no-restricted-globals
    const isConfirm = confirm("Are you sure you want to delete?");
    console.log("confirm", isConfirm);
    if (!isConfirm) {
      return;
    }

    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/narayani/productContent/delete/${id}`,
      headers: {}
    };

    setIsLoading(true);
    axios.request(config)
      .then((response) => {
        getAllProducts();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div className='p-4'>
      <div className='block md:flex justify-start md:justify-between items-center my-4'>
        <h1 className="text-xl font-semibold text-[#000000] tracking-wider">
          Product List
        </h1>

        <button
          onClick={() => navigate('/addProduct')}
          className="flex justify-center items-center bg-gradient-to-r mt-4 md:mt-0 from-[#FF3131] to-[#ff9301] text-white font-semibold tracking-wider py-2 px-4 rounded-md hover:bg-red-600 transition duration-300 ease-in-out">
          <img src={product} alt="" width={40} className='mr-2' />
          Add New Product
        </button>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6'>
        {isLoading ?
          [1, 2, 3]?.map((v, i) => (
            <div className="flex flex-col py-8 px-6 shadow-xl gap-4 w-full items-center justify-between rounded-3xl mb-6 md:mb-0 animate-pulse">
              <div className="w-full">
                <div className="h-[200px] w-full bg-gray-300 rounded-lg"></div>
              </div>

              <div className="flex flex-col gap-4 px-5 md:px-3 w-full">
                <div className="h-6 bg-gray-300 rounded-lg w-3/4"></div>
                <div className="h-4 bg-gray-300 rounded-lg w-full"></div>
                <div className="h-4 bg-gray-300 rounded-lg w-full"></div>
                <div className="h-4 bg-gray-300 rounded-lg w-full"></div>
              </div>

              <div className="flex justify-between w-full px-5 md:px-3">
                <div className="w-20 h-10 bg-gray-300 rounded-lg"></div>
                <div className="w-24 h-10 bg-gray-300 rounded-lg"></div>
              </div>
            </div>
          ))
          : allProducts?.map((ele, ind) => (
            <div key={ind} className={`flex flex-col py-8 px-6 shadow-xl gap-4 w-full items-cente justify-between rounded-3xl mb-6 md:mb-0`}>
              <div className='w-full'>
                <img
                  src={ele?.banner?.banner_url}
                  alt="Container"
                  className='h-[200px] w-full object-cover' />
              </div>

              <div className='flex flex-col gap-4 px-5 md:px-3'>
                <h1 className='text-[#1C1C25] tracking-wide text-2xl font-semibold text-start'>
                  {ele?.title}
                </h1>

                <p className='tracking-wider text-[#7B7E86] text-sm lg:leading-8 md:leading-6 leading-8 text-start'>
                  {ele?.banner?.description?.length > 100
                    ?
                    <>{ele?.banner?.description?.substring(0, 100)} {'..'}</>
                    :
                    ele?.banner?.description}
                </p>
              </div>
              <div className='flex justify-between w-full px-5 md:px-3'>
                <button
                  onClick={() => navigate(`/editProduct/${ele?._id}`)}
                  className='w-20 py-2 bg-[#ACBD1C] hover:bg-[#5f6625] rounded-lg text-white tracking-wide flex justify-center items-center gap-2'>
                  <span>
                    <img src={edit} alt="Edit" width={20} />
                  </span>
                  <span>
                    Edit
                  </span>
                </button>
                <button
                  onClick={() => deleteProduct(ele?._id)}
                  className='bg-red-500 hover:bg-red-700 rounded-lg text-white tracking-wide w-24 py-2 flex justify-center items-center gap-2'>
                  <span>
                    <img src={deleteimg} alt="Delete" width={20} />
                  </span>
                  <span>
                    Delete
                  </span>
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Product
