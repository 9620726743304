import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import topImg1 from "../../assets/Anatomage.png";
import topImg2 from "../../assets/LapVision.png";
import topImg3 from "../../assets/Robotics.png";
import Anatomy from "../../assets/antomage.mp4";
import lapVision from "../../assets/lapvission.mp4";
import Robot from '../../assets/roboticSystem.mp4';

const AboutUsSection = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <div className="mb-5">
      <div data-aos="fade-up">
        <Carousel
          showThumbs={false}
          autoPlay
          infiniteLoop
          showStatus={false}
          showIndicators={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...arrowStyles, left: 15 }}
              >
                ❮
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...arrowStyles, right: 15 }}
              >
                ❯
              </button>
            )
          }
        >
          <div>
            <img src={topImg1} alt="Image 1" className="w-full object-cover" />
          </div>
          <div>
            <img src={topImg2} alt="Image 2" className="w-full object-cover" />
          </div>
          <div>
            <img src={topImg3} alt="Image 3" className="w-full object-cover" />
          </div>
        </Carousel>
      </div>
      <div data-aos="fade-up" className="md:px-10 px-4 overflow-hidden">
        <div className="flex flex-col gap-6 py-4">
          <h1 className="text-3xl tracking-wider font-bold text-center py-4">
            <span className="border-b-2 border-b-[#ACBD1C] py-2">About Us</span>
          </h1>

          <p className="text-center font-sm text-[#74789C] leading-6 tracking-wide">
            Narayani Alliance Private Limited was founded in 2006 with the goal
            of providing the latest technology to its customers and inspiring
            the next generation. NA has been transforming technology in the
            Medical and Engineering sector for the last 16 years. From 2021
            onwards, our ever-growing and diversified solution approach has made
            us a Global company. We are an innovative team of Doctors and
            Engineers who have an in-depth knowledge of 3D, Simulation,
            Engineering, and Medical sciences. We work very closely with the
            Medical and Engineering Fraternity to conceptualize, develop, and
            integrate state-of-the-art skill training & Competency building
            simulators which help in improving patient safety.
          </p>

          <div className="flex justify-center">
            <button
              data-aos="flip-left"
              onClick={() => navigate("/about")}
              className="bg-[#ACBD1C] px-4 py-2 rounded-lg text-white font-semibold tracking-wide"
            >
              Know More
            </button>
          </div>

          <div className="flex lg:flex-row flex-col justify-between gap-4 mt-6 lg:h-[450px] h-full">
            <div className="relative flex-1 h-full">
              <video className="w-full h-full object-cover rounded-md" controls>
                <source src={Anatomy} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="relative flex-1 h-full">
              <video className="w-full h-full object-cover rounded-md" controls>
                <source src={Robot} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="relative flex-1 h-full">
              <video className="w-full h-full object-cover rounded-md" controls>
                <source src={lapVision} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  top: "calc(50% - 15px)",
  width: 30,
  height: 30,
  cursor: "pointer",
  background: "none",
  border: "none",
  fontSize: "2rem",
  color: "white",
};

export default AboutUsSection;
