import React, { useEffect } from "react";
import footerlogo from "../../assets/footerlogo.png";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { MdOutlineLocalPhone } from "react-icons/md";
import { IoMailOutline } from "react-icons/io5";
import { MdOutlineLocationOn } from "react-icons/md";
import img from "../../assets/testimonial.png";
import { useNavigate } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";

const Footer = ({ openModal }) => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <React.Fragment>
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1000"
        className="mb-20 mt-10 relative"
      >
        <img
          src={img}
          alt="Testimonial Back-ground"
          className="w-full object-cover"
        />

        <div className="absolute top-10 sm:top-16 md:top-20 xl:top-40 px-4 md:px-10 w-full">
          <div className="flex justify-between items-center bg-[#1E1E1E] rounded-md p-3 md:p-6 lg:p-10 flex-col md:flex-row gap-2">
            <div className="flex flex-col gap-4">
              <h1 className="text-xl md:text-4xl lg:text-6xl text-white tracking-wider">
                We are there for you !
              </h1>
              <p className="hidden sm:block sm:text-xs md:text-lg text-white tracking-wider">
                You Want to know more about any product or you want to buy it
                call us now .
              </p>
            </div>
            <div className="flex gap-4">
              <button
                onClick={openModal}
                className="bg-[#ACBD1C] text-[#1E1E1E] font-semibold rounded-lg text-center py-2 px-6 md:text-md"
              >
                Inquiry
              </button>
              {/* <button className='bg-[#3E4B62] text-white font-semibold rounded-lg text-center md:text-md py-2 px-2 sm:px-4'>
                                Call us
                            </button> */}
            </div>
          </div>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="flex flex-col md:flex-row md:justify-between gap-6 px-4 md:px-10 bg-[#0E1821] md:py-10 py-6"
      >
        {/* Logo and About Section */}
        <div className="w-full md:w-1/3 flex flex-col gap-4">
          <div className="flex justify-center md:justify-start">
            <img src={footerlogo} alt="Logo" className="max-w-[60%]" />
          </div>
          <p className="text-white tracking-wide text-justify md:text-start leading-7">
            Narayani Alliance Private Limited was founded in 2006 with the goal
            of providing the latest technology to its customers and inspiring
            the next generation. From 2021 onwards...
          </p>
          <div className="flex justify-center md:justify-start gap-2">
            <span className="bg-[#D6DBAE] p-2 content-center rounded-lg cursor-pointer">
              <FaFacebookF />
            </span>
            <span className="bg-[#D6DBAE] p-2 content-center rounded-lg cursor-pointer">
              <FaLinkedinIn />
            </span>
            <span className="bg-[#D6DBAE] p-2 content-center rounded-lg cursor-pointer">
              <FaTwitter />
            </span>
            <span className="bg-[#D6DBAE] p-2 content-center rounded-lg cursor-pointer">
              <FaYoutube />
            </span>
            <span className="bg-[#D6DBAE] p-2 content-center rounded-lg cursor-pointer">
              <IoLogoInstagram />
            </span>
          </div>
        </div>

        {/* Products Section */}
        <div className="w-full md:w-1/4 flex flex-col gap-4 items-center md:items-start">
          <h1 className="text-xl text-white font-semibold tracking-wide">
            Products
          </h1>
          <div className="flex flex-col gap-4 text-white tracking-wide items-center md:items-start">
            <span
              onClick={() => navigate(`/products/Anatomage`)}
              className="cursor-pointer"
            >
              Antomage
            </span>
            <span
              onClick={() => navigate(`/products/LapVision`)}
              className="cursor-pointer"
            >
              LapVision
            </span>
            <span
              onClick={() => navigate("/products/Robotic System")}
              className="cursor-pointer"
            >
              Robotic System
            </span>
          </div>
        </div>

        {/* Contact Section */}
        <div className="w-full md:w-1/3 flex flex-col gap-4 items-center md:items-start">
          <h1 className="text-xl text-white font-semibold tracking-wide">
            Contact
          </h1>
          <div className="flex flex-col gap-4 items-center md:items-start">
            <div className="flex gap-2 items-center">
              <span className="text-[#ACBD1C]">
                <MdOutlineLocalPhone />
              </span>
              <span className="text-white tracking-wide">+91 9438022552</span>
            </div>
            <div className="flex gap-2 items-center">
              <span className="text-[#ACBD1C]">
                <IoMailOutline />
              </span>
              <span className="text-white tracking-wide text-wrap">
                narayaniallianceqbc@gmail.com
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <span className="text-[#ACBD1C]">
                <MdOutlineLocationOn />
              </span>
              <span className="text-white tracking-wide">
                Plot No.529/3, Sampur, near Sum Hospital, Bhubaneswar, Odisha -
                751003
              </span>
            </div>
            <div className="flex flex-col md:ml-6 items-center">
              <div className="tracking-wide text-white  mb-4 md:mb-0 w-full">
                2024 © All Rights Reserved.
              </div>
              <div className="text-center text-white w-full">
                Design & Developed by{" "}
                <a href="#" className="underline text-orange-500">
                  @ Web-Bocket
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
