import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';

const Inquiry = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-overlay') {
      onClose();
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.phone) {
      errors.phone = "Phone Number is required";
    }
    if (!formData.message) {
      errors.message = "Message is required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      emailjs.sendForm("service_5gtbihv", "template_b3a8v3r", formRef.current, "HnyojR6K_d1Enn4qw")
        .then(
          (result) => {
            console.log(result.text);
            setLoading(false);
            setFormData({ name: "", email: "", phone: "", message: "" });
            setErrors({});
            alert("Message Sent Successfully");
            onClose();
          },
          (error) => {
            console.log(error.text);
            setLoading(false);
          }
        );
    }
  };

  if (!isOpen) return null;

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleOutsideClick}
    >
      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg relative w-11/12 sm:w-96 max-w-lg" onClick={(e) => e.stopPropagation()}>
        <span
          onClick={onClose}
          className='absolute top-4 sm:top-6 right-4 sm:right-5 cursor-pointer text-xl'>
          x
        </span>
        <h2 className="text-xl font-bold mb-4 text-center">Inquiry</h2>
        <div className="mx-auto">
          <form className="grid space-y-3" ref={formRef} onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              className="h-10 rounded-md px-3 w-full py-2 border text-lg"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
            />
            {errors.name && <div className="text-red-500 text-sm">{errors.name}</div>}
            <input
              type="text"
              name="phone"
              className="h-10 rounded-md px-3 w-full py-2 border text-lg"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleInputChange}
            />
            {errors.phone && <div className="text-red-500 text-sm">{errors.phone}</div>}
            <textarea
              name="message"
              className="rounded-md px-3 w-full py-2 border text-lg"
              placeholder="Message"
              value={formData.message}
              onChange={handleInputChange}
            />
            {errors.message && <div className="text-red-500 text-sm">{errors.message}</div>}
            <button
              type="submit"
              className="h-11 tracking-wider font-medium bg-[#ACBD1C] hover:bg-[#5f6625] text-gray-100 text-lg rounded-md transition ease-in-out hover:duration-300"
              disabled={loading}
            >
              {loading ? "Please Wait.." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Inquiry;
