import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const Banner = ({ product }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out",
    });

    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <div className="mb-5">
      <div className="max-h-[450px] overflow-hidden relative">
        {loading ? (
          <div className="animate-pulse bg-gray-300 h-[450px] w-full"></div>
        ) : (
          <img
            data-aos="fade-up"
            src={product?.banner?.banner_url}
            alt="Banner"
            className="object-cover w-full"
          />
        )}
        <div className="absolute bottom-0 sm:bottom-10 md:bottom-16 lg:bottom-24 left-2 md:left-5 px-16 flex flex-col gap-4"></div>
      </div>
      <div className="px-4 md:px-10 my-5">
        {loading ? (
          <div className="flex flex-col gap-6 text-center">
            <div className="animate-pulse bg-gray-300 h-10 w-1/2 mx-auto"></div>
            <div className="animate-pulse bg-gray-300 h-5 w-full mx-auto"></div>
            <div className="animate-pulse bg-gray-300 h-5 w-3/4 mx-auto"></div>
            <div className="animate-pulse bg-gray-300 h-10 w-1/2 mx-auto"></div>
            <div className="animate-pulse bg-gray-300 h-5 w-full mx-auto"></div>
            <div className="animate-pulse bg-gray-300 h-5 w-3/4 mx-auto"></div>
          </div>
        ) : (
          <>
            <h1 className="text-4xl font-semibold text-center text-[#111111]">
              {product?.about_section1?.heading}
            </h1>
            <div className="flex flex-col gap-6 text-[#74789C] my-5">
              <p
                data-aos="flip-left"
                className="text-center tracking-wider leading-8"
              >
                {product?.about_section1?.paragraph1}
              </p>
              <h1 className="text-4xl font-semibold text-center text-[#111111]">
                {product?.about_section1?.heading2}
              </h1>
              <p
                data-aos="flip-right"
                className="text-center tracking-wider leading-8"
              >
                {product?.about_section1?.paragraph2}
              </p>
            </div>
          </>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 place-items-center px-4 md:px-10 my-8">
        {loading
          ? Array.from({ length: 3 }).map((_, ind) => (
              <div
                className="animate-pulse bg-gray-300 h-48 w-full"
                key={ind}
              ></div>
            ))
          : product?.about_section1?.imageArr?.map((img, ind) => (
              <div
                data-aos={ind % 2 === 0 ? "flip-right" : "flip-left"}
                key={ind}
              >
                <img src={img} alt={`Product-${ind}`} />
              </div>
            ))}
      </div>
    </div>
  );
};

export default Banner;


          {/* <h1 data-aos="fade-left" className='text-white text-4xl sm:text-6xl md:text-7xl lg:text-8xl tracking-wider font-semibold'>
                        {product?.banner?.title?.slice(0, 3)}
                        <span className='text-[#ACBD1C]'>
                            {product?.banner?.title?.slice(3)}
                        </span>
                    </h1> */}
          {/* <p data-aos="fade-left" className='text-sm sm:text-lg md:text-xl lg:text-3xl tracking-wider text-[#E8E8EE]'>
                        {product?.banner?.description}
                    </p> */}

     {/* <div className="relative pb-[56%] md:pb-[35%] lg:pb-[28%]">
        <video
          className="absolute top-0 left-0 w-full h-full md:h-[400px] px-4 md:px-10"
          controls
          src={product?.about_section1?.video_url}
          title="Product video"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></video>
      </div> */}
