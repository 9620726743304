import Login from "../screens/nonAuth/Login";
import Dashboard from "../screens/auth/Dashboard";
import Home from "../screens/users/Home";
import Contact from "../screens/users/Contact";
import About from "../screens/users/About";
import Products from "../screens/users/Products";
import ForgotPassword from "../screens/nonAuth/ForgotPassword";
import Product from "../screens/auth/Product";
import AddProduct from "../components/productComponent/AddProduct";
import EditProduct from "../components/productComponent/EditProduct";

export const authRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/product",
    component: <Product />,
  },
  {
    path: "/addProduct",
    component: <AddProduct />,
  },
  {
    path: "/editProduct/:id",
    component: <EditProduct />,
  },
];

export const nonAuthRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
];

export const userRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/about",
    component: <About />,
  },
  {
    path: "/products/:productName",
    component: <Products />,
  },
];
