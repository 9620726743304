import React, { useEffect, useState } from 'react';
import { GoDotFill } from "react-icons/go";
import 'aos/dist/aos.css';
import AOS from 'aos';

const ProductContent = ({ product }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
        });

        setTimeout(() => setLoading(false), 1000);
    }, []);

    return (
        <div className='md:px-10 px-4 md:mt-32 lg:mt-10'>
            {loading ? (
                <div className='flex flex-col md:flex-row my-5 gap-4'>
                    <div className='md:w-1/2 w-full animate-pulse bg-gray-300 h-64'></div>
                    <div className='w-full md:w-1/2 flex flex-col justify-center gap-6'>
                        <div className='animate-pulse bg-gray-300 h-8 w-1/2 mx-auto'></div>
                        <div className='flex flex-col gap-2'>
                            <div className='animate-pulse bg-gray-300 h-6 w-full'></div>
                            <div className='flex flex-col gap-2'>
                                <div className='animate-pulse bg-gray-300 h-4 w-full'></div>
                                <div className='animate-pulse bg-gray-300 h-4 w-3/4'></div>
                                <div className='animate-pulse bg-gray-300 h-4 w-2/3'></div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                product?.section2 && (
                    <div data-aos="flip-left" className='flex flex-col md:flex-row my-5 gap-4'>
                        {product?.section2?.img_url && (
                            <div className='md:w-1/2 w-full'>
                                <img src={product?.section2?.img_url} alt="ImageContent" className='object-cover h-full w-full md:w-[90%]' />
                            </div>
                        )}
                        <div className='w-full md:w-1/2 flex flex-col justify-center gap-6'>
                            <h1 className='text-3xl text-[#000541] tracking-wider font-semibold px-5'>
                                {product?.section2?.heading ? (
                                    <>
                                        {product?.section2?.heading?.split(' ')[0]}{" "}
                                        <span className='text-[#ACBD1C]'>
                                            {product?.section2?.heading?.split(' ')?.slice(1)?.join(' ')}
                                        </span>
                                    </>
                                ) : (
                                    ""
                                )}
                            </h1>
                            {product?.section2?.points?.map((ele, ind) => (
                                <div key={ind} className='flex flex-col gap-2'>
                                    <p className='text-[#000541] text-lg tracking-wide font-semibold px-5'>
                                        {ele?.title}
                                    </p>
                                    <div className='text-[#74789C] tracking-wide flex flex-col gap-2'>
                                        {ele?.keyPoints?.map((v, i) => (
                                            <div key={i} className='flex gap-2 items-center'>
                                                <span>
                                                    <GoDotFill />
                                                </span>
                                                <span>
                                                    {v ?? v}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            )}

            {loading ? (
                Array.from({ length: 3 }).map((_, index) => (
                    <div key={index} className={`flex items-center flex-col h-full md:h-[450px] overflow-hidden gap-6 ${index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
                        <div className='w-full md:w-1/2 animate-pulse bg-gray-300 h-64'></div>
                        <div className='w-full md:w-1/2 flex flex-col gap-6'>
                            <div className='animate-pulse bg-gray-300 h-8 w-1/2 mx-auto'></div>
                            <div className='flex flex-col gap-2'>
                                <div className='animate-pulse bg-gray-300 h-6 w-full'></div>
                                <div className='animate-pulse bg-gray-300 h-6 w-3/4'></div>
                                <div className='animate-pulse bg-gray-300 h-6 w-2/3'></div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                product?.section3?.map((service, index) => (
                    <div data-aos={index % 2 === 0 ? "flip-left" : "flip-right"} className={`flex items-center flex-col h-full md:h-[450px] overflow-hidden gap-6 ${index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'}`} key={index}>
                        <div className='w-full md:w-1/2 flex gap-6 flex-col'>
                            <h1 className='text-3xl text-[#000541] tracking-wider font-semibold'>
                                {service?.title ? (
                                    <>
                                        {service?.title?.split(' ')[0]}{" "}
                                        <span className='text-[#ACBD1C]'>
                                            {service?.title?.split(' ')?.slice(1)?.join(' ')}
                                        </span>
                                    </>
                                ) : (
                                    ""
                                )}
                            </h1>
                            <div className='text-[#000541] flex flex-col gap-4'>
                                <p className='leading-7 text-[#74789C] text-justify tracking-wide'>
                                    {service?.description}
                                </p>
                                <div className='tracking-wide flex flex-col gap-2 px-2'>
                                    {service?.features?.map((feature, index) => (
                                        <div className='flex gap-2 items-center' key={index}>
                                            <span>
                                                <GoDotFill />
                                            </span>
                                            <span>
                                                {feature}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='w-full md:w-1/2 md:h-[430px]'>
                            <img src={service?.img_url} alt="ProductImg" className='w-full h-full rounded-lg' />
                        </div>
                    </div>
                ))
            )}

            <div className='flex flex-col items-center gap-10 py-10'>
                {loading ? (
                    <>
                        <div className='animate-pulse bg-gray-300 h-8 w-1/2 mx-auto'></div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                            {Array.from({ length: 4 }).map((_, ind) => (
                                <div key={ind} className='flex flex-col gap-4'>
                                    <div className='animate-pulse bg-gray-300 h-6 w-full'></div>
                                    <div className='flex flex-col gap-2'>
                                        <div className='animate-pulse bg-gray-300 h-4 w-full'></div>
                                        <div className='animate-pulse bg-gray-300 h-4 w-3/4'></div>
                                        <div className='animate-pulse bg-gray-300 h-4 w-2/3'></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <h1 className='text-3xl font-semibold tracking-wide text-center text-[#111111]'>
                            {product?.section4?.heading}
                        </h1>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                            {product?.section4?.points?.map((ele, ind) => (
                                <div key={ind} className='flex flex-col gap-4'>
                                    <p data-aos={ind % 2 === 0 ? "zoom-in-left" : "zoom-in-right"} className='text-[#000541] text-lg tracking-wide font-semibold'>
                                        {ele?.title}
                                    </p>
                                    <div className='text-[#74789C] tracking-wide flex flex-col gap-2'>
                                        {ele?.keyPoints?.map((val, i) => (
                                            <div data-aos={i % 2 === 0 ? "zoom-in-left" : "zoom-in-right"} key={i} className='flex gap-2 items-center'>
                                                <span>
                                                    <GoDotFill />
                                                </span>
                                                <span>
                                                    {val}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default ProductContent;
